<template>
    <div>
        <b-card>
            <div class="btn-back">
                <b-button
                    :to="{ name: 'housing-list'}"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                >
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                </b-button>
            </div>

            <b-overlay :show="isLoadingData || isUpdatingHousing ">
                <HousingFormEdit
                    :isLoadingData="isLoadingData"
                    :isUpdatingHousing="isUpdatingHousing"
                    :housingData="housingData"
                    @update-housing="update"
                    v-if="housingData"
                />
            </b-overlay>

            <!-- <b-tabs>
                <b-tab active>
                    <template #title><span>Información General</span></template>
                    <b-overlay :show="isLoadingData || isUpdatingHousing ">
                        <HousingFormEdit
                            :isLoadingData="isLoadingData"
                            :isUpdatingHousing="isUpdatingHousing"
                            :housingData="housingData"
                            @update-housing="update"
                            v-if="housingData"
                        />
                    </b-overlay>
                </b-tab>
                <b-tab>
                    <template #title><span>Cuotas Condominales</span></template>

                    <CondominiumFeesHousing
                        :isLoadingData="isLoadingData"
                        :condominiumFees="condominiumFees"
                        :paymentTypes="paymentTypes"

                    />

                </b-tab>
            </b-tabs> -->

        </b-card>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex"
    import Ripple from 'vue-ripple-directive'
    import HousingFormEdit from "@/modules/fivesClub/components/catalogs/housing/HousingFormEdit"
    import CondominiumFeesHousing from "@/modules/fivesClub/components/catalogs/condominiums/CondominiumFeesHousing"
    import { showAlertMessage } from '@/helpers/helpers'


    export default {
        directives: {Ripple, },
        components: {
            HousingFormEdit,
            CondominiumFeesHousing
        },
        async created() {
            await this.init()
        },
        data() {
            return {
                housingData: null,
                condominiumFees: [],
                isLoadingData: false,
                isUpdatingHousing: false,
                paymentTypes: []     ,
            }
        },
        computed: {
            ...mapState('fivesClubCatalogs', ['subCondos']),
            ...mapState('auth', ['user']),
        },
        methods: {
            ...mapActions('fivesClubCatalogs', ['fetchHousing', 'fetchHousingCondominalFees','updateHousing','fetchPaymentMethodsFeeQuota']),
            ...mapActions('groups', ['fetchRoomsTypes']),
            ...mapMutations('fivesClubCatalogs',['setHousing', 'setCondominiumFees']),
            ...mapMutations('groups',['setRoomsTypes']),

            async init(){
				this.isLoadingData = true
                const idHousing = this.$route.params.id
                this.setCondominiumFees([])

                if(this.isANumber(idHousing)){
                    const housing = await this.fetchHousing({idHousing})
                    this.housingData = housing[0]
                    // console.log(this.housingData)
                    await this.setHousingRooms()
                    // await this.getCondominalFees() comentado porque ya no se ocupa aquí
                    this.paymentTypes = await this.fetchPaymentMethodsFeeQuota()
                }
				this.isLoadingData = false
            } ,
            isANumber(string){
                return string ? !isNaN( string ) && string > 0 : false
            },
            async getCondominalFees(){
                if(this.housingData){
                    const condominiumFees = await this.fetchHousingCondominalFees(this.housingData.id)
                    const quarter = { 1 : 1, 2 : 1, 3 : 1, 4 : 1, 5 : 2, 6 : 2, 7 : 2, 8 : 2, 9 : 3, 10 : 3, 11 : 3, 12: 3 }
                    condominiumFees.forEach( condFee => {
                        condFee.isSelectedItem = false
                        condFee.isLoadingDetails = false
                        condFee.quarter = quarter[condFee.monthCondoFeeHousing]
                        condFee.detailsFee = []
                    })

                    this.setCondominiumFees(condominiumFees)
                }
            },
            async setHousingRooms(){
                this.setRoomsTypes([])
                const subcondo = this.subCondos.find(subcondo=> subcondo.id === this.housingData.subCondos)
                if (subcondo){
                    await this.fetchRoomsTypes(subcondo.resorts)
                }
            },
            async update() {
                this.isUpdatingHousing = true;
                const payload = {
                    idUser: this.user.idUser,
                    id: this.housingData.id,
                    name: this.housingData.name,
                    numberHousing: this.housingData.numberHousing,
                    price: this.housingData.price,
                    subCondos: this.housingData.subCondos,
                    points: this.housingData.points,
                    percentage: this.housingData.percentage,
                    qtyrooms: this.housingData.rooms,
                    housingRoom: this.housingData.categoryroomsid,
                    status: Boolean(this.housingData.status),
                    firstRemodelationYear: !this.housingData.firstRemodelationYear ? null : this.housingData.firstRemodelationYear
                }

                const { status, message } = await this.updateHousing(payload)

                if (status) {
                    showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                    await this.init()
                }

                this.isUpdatingHousing = false;
            },

        },
    };
</script>
<style scoped>
.btn-back{
    display: flex;
    justify-content: flex-end;
}
</style>